.alert-line-item {
  display: flex;
  flex-direction: row;
  margin-top: 0;
  margin-bottom: 40px;
}

.alert-line-item p {
    text-align: left;
    margin: 0 !important;
}

.alert-line-item .icon {
    background: var(--sec-bg-color);
    margin-right: 20px;
    stroke: var(--pri-color-light);
    padding: 10px;
    border-radius: 10px;
    min-width: 18px;
    max-width: 18px;
}

.alert-line-item .mapping-field {
    margin-bottom: 0;
}

.alert-line-item h3 {
    margin-top: 10px;
    font-size: 12px;
}