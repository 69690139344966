.create-superadmin-center p {
  margin-bottom: 20px;
}

.enter-pass1 {
  margin-bottom: 30px !important;
}
.enter-pass2 {
  margin-bottom: 40px !important;
}

.invalid-username {
  margin-bottom: 5px;
  text-align: end;
  width: 100%;
  color: var(--contrast-color);
  margin-right: 10px;
  font-weight: 400;
  margin-top: 0px !important;
}

.valid-username {
  margin-bottom: 5px;
  text-align: end;
  width: 100%;
  color: var(--green-color);
  margin-right: 10px;
  font-weight: 400;
  margin-top: 0px !important;
}

.enter-username {
  margin-bottom: 30px !important;
}

.enter-username-valid .MuiOutlinedInput-notchedOutline,
.enter-pass1-valid .MuiOutlinedInput-notchedOutline,
.enter-pass2-valid .MuiOutlinedInput-notchedOutline {
  border-width: 1px;
  border-color: var(--green-color) !important;
}
.enter-username-valid .MuiInputLabel-root,
.enter-pass1-valid .MuiInputLabel-root,
.enter-pass2-valid .MuiInputLabel-root {
  color: var(--green-color) !important;
}

.enter-username-invalid .MuiOutlinedInput-notchedOutline,
.enter-pass1-invalid .MuiOutlinedInput-notchedOutline,
.enter-pass2-invalid .MuiOutlinedInput-notchedOutline {
  border-width: 1px;
  border-color: var(--contrast-color) !important;
}
.enter-username-invalid .MuiInputLabel-root,
.enter-pass1-invalid .MuiInputLabel-root,
.enter-pass2-invalid .MuiInputLabel-root {
  color: var(--contrast-color) !important;
}

.pass-strength {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  gap: 5px;
  width: 100%;
}

.pass-strength-on {
  width: 25%;
  height: 3px;
  background: var(--contrast-color);
}

.pass-strength-off {
  width: 25%;
  height: 3px;
  background: var(--bor-color);
}

.create-superadmin-account-button {
  border-radius: 40px;
  margin-left: auto;
  margin-top: 30px;
  padding: 20px;
}
