.single-string-modal-bg {
  min-width: 100vw;
  z-index: 200 !important;
  backdrop-filter: blur(5px);
  min-height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: fadein 0.5s;
  top: 0;
  left: 0;
}

@keyframes fadein {
  from {
    background: rgba(0, 0, 0, 0);
  }
  to {
    background: rgba(0, 0, 0, 0.2);
  }
}

.single-string-modal {
  position: relative;
  background: var(--bg-color);
  border: 1px solid var(--bor-color);
  width: 70vw;
  height: calc(100vh - 140px);
  padding: 20px;
  z-index: 1001;
  overflow: hidden;
  overflow-y: scroll;
  margin-top: 40px;
  max-width: 800px;
  animation: popup 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.1); 
  width: 350px;
  height: fit-content;
  overflow: hidden;
}

@keyframes popup {
    0% {
        transform: scale(0.8);
    }
    100% {
        transform: none;
    }
}

.single-string-modal .icon {
  cursor: pointer;
  position: absolute;
  top: 20px !important;
  right: 20px !important;
  z-index: 1002;
  width: 18px;
  height: 18px;
  margin: 0 !important;
}

.single-string-modal .icon:hover {
  stroke: var(--sec-color);
}

.add-single-string h1 {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.single-string-name-input {
  margin-top: 20px;
  border: 1px solid var(--bor-color);
  padding: 8px;
  display: flex;
  align-items: center;
  border-radius: 5px;
}

.single-string-modal .icon {
  top: 0px;
}

.single-string-name-input input {
  margin-right: auto;
  width: 100%;
}

.single-string-name-input textarea {
  margin-right: auto;
  width: 100%;
  background: none;
  height: 100px;
  font-family: 'Roboto', sans-serif;
}

.single-string-name-input button {
  outline: none;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  transition: 0.2s;
  pointer-events: none;

  background: var(--sec-bg-color);
  color: var(--pri-color-light);
}

.button-alive {
  box-shadow: var(--box-shadow-s) !important;
  background: var(--sec-color) !important;
  color: white !important;
  pointer-events: all !important;
}

.single-string-name-input button:hover {
  box-shadow: var(--box-shadow-l) !important;
}
