.cve-db-setting {
  margin-bottom: 100px;
}

.cve-db-setting p {
  color: var(--pri-color-light);
  max-width: 600px;
  text-align: left;
  margin-bottom: 15px;
  margin-top: 15px;
  overflow: hidden;
}

.cve-db-setting p:nth-of-type(1) {
  margin-top: 20px;
}

.cve-db-setting .lds-dual-ring {
  border: none;
  min-width: auto;
  max-width: auto;
}