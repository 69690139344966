.analysis-queue {
  overflow: hidden;
  position: absolute;
  top: 39px;
  right: -2px;
  border: 1px solid transparent;
  border-radius: 10px;
  border-top-right-radius: 0;
  height: fit-content;
  z-index: 0;
  background: var(--bg-color);
  border: 2px solid var(--sec-color);
  box-shadow: 0px 16px 10px 0px rgb(0 0 0 / 14%),
    0px 11px 18px 0px rgb(0 0 0 / 12%), 0px 13px 5px -1px rgb(0 0 0 / 20%);
  max-height: 90vh;
  height: calc(100vh - 160px);
  display: flex;
}

.analysis-queue-modal-bg {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  width: 200vw !important;
  height: 200vh !important;
}

.head-prog {
  padding: 15px;
  border-radius: 10px;
  background: var(--bg-color);
  z-index: 300;
}

.analysis-queue-fold {
  background: var(--bg-color);
  position: absolute;
  height: 4px;
  bottom: 5px;
  right: 0px;
  width: 100%;
  z-index: 100;
}

.analysis-queue-left {
  width: 420px;
  height: 100%;
  overflow-y: scroll;
}

.analysis-queue-right {
  width: 420px;
  height: calc(100% - 50px);
  position: relative;
}

.analysis-queue-right-header {
  height: 50px;
  border-bottom: 2px solid var(--sec-bg-color);
  width: 100%;
  display: flex;
  align-items: center;
}

.analysis-queue-right-header .icon {
  margin-left: auto;
  width: 18px;
  height: 18px;
  background: var(--sec-bg-color);
  padding: 10px;
  border-radius: 10px;
  margin-right: 10px;
  stroke: var(--pri-color-light);
  cursor: pointer;
  transition: 0.2s;
}

.analysis-queue-right-header .icon:hover {
  stroke: var(--sec-color);
  outline: 2px solid var(--sec-color);
}

.analysis-queue-right-scroller {
  overflow-y: scroll;
  height: 100%;
}

.analysis-queue-assessment {
  padding: 30px;
  border-bottom: 2px solid var(--sec-bg-color);
  display: flex;
  align-items: center;
  gap: 20px;
  background: var(--bg-color);
  width: calc(100% - 60px);
  position: relative;
  overflow: visible;
  z-index: 10;
}

.analysis-queue-assessment .span {
  display: flex;
  align-items: center;
}

.analysis-queue-assessment .span h3 {
  white-space: nowrap;
  overflow: none;
  margin-right: 5px;
  text-overflow: ellipsis;
  max-width: 50px !important;
}

.analysis-queue-assessment .span h3:nth-of-type(2) {
  margin-right: 0px;
  max-width: 70px !important;
}

.analysis-queue-assessment h3 {
  max-width: 150px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.analysis-queue-assessment div h2 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 5px;
  max-width: 150px !important;
}

.analysis-queue-assessment-progress-state {
  margin-left: auto !important;
}

.analysis-queue-assessment h2 {
  font-size: 15px;
}

.analysis-queue-assessment:hover {
  background: var(--sec-bg-color);
}

.analysis-queue-assessment:hover .delete-assessment-button {
  display: block;
}

.delete-assessment-button {
  position: absolute;
  display: none;
  bottom: 25px;
  border-radius: 100%;
  cursor: pointer;
  right: 30px;
  stroke: var(--pri-color-light);
  transition: 0.2s;
  border: 2px solid var(--sec-bg-color);
  background: var(--bg-color);
  width: 18px;
  height: 18px;
  padding: 10px;
}

.delete-assessment-button:hover {
  border: 2px solid var(--sec-color);
  stroke: var(--sec-color);
}

.analysis-queue-assessment:hover .analysis-queue-assessment-progress-state {
  display: none !important;
}

.analysis-queue-assessment-stopped h2 {
  color: var(--bor-color) !important;
  font-weight: 500;
}

.analysis-queue-assessment-stopped h3 {
  color: var(--bor-color);
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.analysis-queue-assessment-completed h2 {
  color: var(--bor-color) !important;
  font-weight: 500;
}

.analysis-queue-assessment-completed h3 {
  color: var(--bor-color);
  overflow: hidden;
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.analysis-queue-assessment-completed .span {
  color: var(--bor-color);
}

.analysis-queue-asset {
  padding: 30px;
  border-bottom: 2px solid var(--sec-bg-color);
  background: var(--bg-color);
  outline-offset: -2px;
}

.analysis-queue-asset:hover {
  outline: 2px solid var(--sec-color);
}

.analysis-queue-asset-started {
  cursor: normal !important;
}
.analysis-queue-asset-started:hover {
  outline: none !important;
}

.asset-analysis-button-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.asset-analysis-button-row h3 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
}

.asset-analysis-name-tooltip {
  width: 200px;
}

.asset-analysis-button-row button {
  color: var(--pri-color-light);
  width: fit-content;
  border-radius: 10px;
  padding: 20px;
  margin-right: 10px;
  margin-left: auto;
  min-width: 150px;
}

.asset-analysis-button-row button:nth-of-type(2) {
  background: var(--bg-color);
  border: 2px solid var(--sec-bg-color);
}

.analysis-queue-assessment-progress-state div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.analysis-queue-assessment-progress-state progress {
  margin-bottom: 15px;
}

.analysis-queue-binary {
  width: 100%;
  padding-left: 28px;
  padding-right: 28px;
  transform: translateX(-28px);
  background: var(--bg-color);
}

.analysis-queue-binary:hover {
  background: var(--sec-bg-color);
}

.bin-prog-row {
  position: relative;
}

.bin-prog-row .delete-assessment-button {
  bottom: 10px;
  right: 25px;
}

.bin-prog-row:hover .delete-assessment-button {
  display: block;
}

.bin-prog-row:hover .bin-prog-row-circ {
  display: none;
}

.queue-stalled-cover {
  position: absolute;
  cursor: auto;
  z-index: 100;
  width: 100%;
  height: 100%;
  top: 52px;
  left: 0;
  pointer-events: none;
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.1);
  transition: 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.queue-stalled-cover h3 {
  font-size: 18px;
}

.num-bins-more {
  margin-top: 15px;
}

.binary-progress-doughnut {
  margin-top: 10px;
  margin-left: 3px;
}

.binary-progress-doughnut-circle {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
