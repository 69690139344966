.server-updates h3 {
  color: var(--pri-color-light);
  font-weight: 600;
  margin-bottom: 30px;
}

.server-updates h2 {
  font-size: 15px;
  margin-bottom: 15px;
}

.server-updates p {
  text-align: left;
  line-height: 1.4;
}

.server-updates .spinner {
  height: 20px;
  width: 20px;
  stroke: var(--pri-color-light);
  margin: 0;
  margin-left: 10px;
}

.server-update-form {
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.server-update-form div {
  border: 1px solid var(--bor-color);
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  min-width: 300px;
  max-width: 300px;
}

.change-password-row button,
.server-update-form button {
  background: var(--sec-color);
  color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: 0.2s;
}

.server-update-form button:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important;
}

.server-update-form p {
  margin-left: 10px;
  font-weight: 400;
  color: var(--pri-color-light);
}

.change-password-row {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.deployment-settings hr {
  margin: 0;
  width: 100%;
  max-width: 600px;
  margin-top: 30px;
  margin-bottom: 100px;
}

.deployment-settings h2 {
  font-size: 16px;
}
