.symbex-tab {
  height: calc(100% + 30px);
  width: calc(100% + 60px);
  margin-left: -30px;
  margin-top: -30px;
  z-index: 1000;
}

.symbex-tab h1 {
  font-style: normal;
}

.symbex-tab .dangr-search-input {
  width: auto !important;
}

.dangr-log-entry p {
  text-align: left !important;
  line-height: 1.4;
  padding-top: 10px;
  padding-bottom: 10px;
}