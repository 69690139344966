.new-analysis-base-info {
  background: var(--bg-color);
  height: 388px;
  width: 50%;
  padding: 20px;
  border: 1px solid var(--bor-color);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  flex-direction: column;
  background: var(--bg-color);
  z-index: 101;
  box-shadow: 0px 16px 10px 0px rgb(0 0 0 / 14%),
    0px 11px 18px 0px rgb(0 0 0 / 12%), 0px 13px 5px -1px rgb(0 0 0 / 20%) !important;
}

.new-analysis-file-holder {
  background: var(--bg-color);
  height: 398px;
  padding-bottom: 30px;
  width: calc(50% + 40px);
  border: 1px solid var(--bor-color);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: column;
  background: var(--bg-color);
  transform-origin: left;
  transform: scale(0.99) translateX(-300px);
  z-index: 100;
  transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  box-shadow: 0px 16px 10px 0px rgb(0 0 0 / 14%),
    0px 11px 18px 0px rgb(0 0 0 / 12%), 0px 13px 5px -1px rgb(0 0 0 / 20%) !important;
}

.new-analysis-base-row {
  display: flex;
  align-items: center;
  width: 100%;
}

.new-analysis-icon {
  stroke: var(--sec-color);
  border-radius: 100%;
  padding: 7px;
  width: 16px;
  height: 16px;
  background: rgba(66, 135, 245, 0.1);
}

.new-analysis-close {
  margin-left: auto;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.new-analysis-close:hover {
  stroke: var(--sec-color);
}

.new-analysis-question {
  margin-top: 10px;
  font-size: 15px;
}

.new-analysis-line {
  width: 100%;
  margin: 0;
  margin-top: 15px;
  margin-bottom: 15px;
}

.new-analysis-next-button {
  background: var(--sec-color);
  width: 100%;
  color: white;
  margin-top: auto;
}

.new-analysis-next-button-off {
  background: var(--sec-bg-color);
  width: 100%;
  color: var(--pri-color-light);
  margin-top: auto;
  cursor: normal;
  pointer-events: none;
}

.new-analysis-option {
  padding: 10px;
  border: 1px solid var(--bor-color);
  margin-right: 10px;
  cursor: pointer;
  color: var(--pri-color-light);
  border-radius: 5px;
}

.new-analysis-option-selected {
  padding: 10px;
  border: 1px solid var(--sec-color);
  color: var(--sec-color);
  margin-right: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.new-analysis-option:hover {
  border: 1px solid var(--sec-color);
  color: var(--sec-color);
}

.new-analysis-input {
  padding: 10px;
  border: 1px solid var(--bor-color);
  margin-right: 10px;
  color: var(--pri-color);
  border-radius: 5px;
  width: calc(100% - 25px);
  margin-top: 10px;
}

.new-analysis-input:hover,
.new-analysis-input:focus {
  border: 1px solid var(--sec-color);
}

.new-analysis-info-bottom {
  cursor: pointer;
}

.new-analysis-info-bottom:hover h3 {
  color: var(--sec-color);
}

.new-analysis-info-bottom:hover .icon {
  stroke: var(--sec-color) !important;
}

.new-analysis-centerpiece {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
}

.new-analysis-centerpiece a {
  font-weight: 500;
  text-decoration: underline;
}

.new-analysis-centerpiece a:hover {
  color: var(--sec-color);
}

.new-analysis-centerpiece h3 {
  text-align: center;
  margin-top: 5px;
  font-size: 15px;
  line-height: 1.5;
}

.new-analysis-centerpiece button {
  background: var(--sec-color);
  color: white;
}

.has-drops {
  justify-content: flex-start;
  height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-left: 20px;
  padding-right: 20px;
}

.file-drop-modal {
  box-shadow: none !important;
  overflow: visible;
}

.more-asset-types {
  border: 1px solid var(--bor-color);
  border-radius: 100%;
  padding: 3px;
  stroke: var(--pri-color-light);
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.more-asset-types:hover {
  border: 1px solid var(--sec-color);
  stroke: var(--sec-color);
}

.asset-type-selector {
  position: relative;
}

.upload-option-or {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;
  margin-top: 30px;
  margin-bottom: 30px;
}

.upload-option-or hr {
  width: 120px !important;
  margin: 0px;
}

.upload-option-or h3 {
  margin-top: 0 !important;
}

.s3-choose-files,
.s3-creds {
  padding: 30px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.s3-creds .MuiFormControl-root {
  margin-bottom: 20px;
}

.s3-choose-files .row,
.s3-creds .row {
  margin-top: auto;
  width: 100%;
}

.s3-choose-files button,
.s3-creds button {
  color: white;
  background: var(--sec-color);
  width: 100%;
}

.s3-choose-files button:nth-of-type(1),
.s3-creds button:nth-of-type(1) {
  margin-right: 10px;
  background: none;
  border: 1px solid var(--bor-color);
  color: var(--pri-color-light);
}

.file-checks {
  overflow-y: scroll;
  height: 310px;
}

.file-check {
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  color: var(--pri-color-light);
  align-items: center;
  width: 260px;
}
.file-check .icon {
  margin-right: 10px;
}
.file-check h3 {
  overflow: hidden;
  max-width: 220px;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 15px;
}

.file-check:hover {
  background: var(--sec-bg-color);
}

.file-check-selected {
  color: var(--sec-color);
  background: var(--sec-bg-color);
}

.file-check-selected h3 {
  color: var(--sec-color);
  font-weight: 600;
}

.starting-bin-analysis {
  backdrop-filter: blur(2px);
  background: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 638px;
  pointer-event: none !important;
  cursor: default;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2000;
}

.starting-bin-analysis h3 {
  margin-top: 10px;
  font-size: 16px !important;
}

.delete-all-dropzone-items {
  background: var(--sec-bg-color);
  color: var(--pri-color-light);
  padding: 15px;
  margin-left: auto;
  align-self: flex-end;
  border: 1px solid var(--bor-color);
  margin-bottom: 15px;
}

.vendor-product-version-inputs {
  border-top: 2px solid var(--sec-bg-color);
  margin-top: 15px;
  padding-top: 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  row-gap: 10px;
}

.vendor-product-version-inputs div {
  width: 46.9%;
}

.vendor-product-version-input {
  position: relative;
}

.vpv-options {
  position: absolute;
  background: var(--bg-color);
  border-left: 1px solid var(--bor-color);
  border-right: 1px solid var(--bor-color);
  border-bottom: 1px solid var(--bor-color);
  border-radius: 0px;
  width: calc(100% - 5px) !important;
  overflow: hidden;
  overflow-y: scroll;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important;
  max-height: 200px;
  z-index: 100;
}

.vpv-option {
  cursor: pointer;
  padding: 15px;
  width: calc(100% - 25px) !important;

  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vpv-option:hover {
  background: var(--sec-bg-color);
}
