.organization-of-users {
  border: 1px solid var(--bor-color) !important;
  border-radius: 10px;

  margin-bottom: 30px;
}

.organization-of-users-head {
  padding: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  background: var(--sec-bg-color);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--bor-color);
  overflow: hidden;
}

.organization-of-users-head h3 {
  white-space: nowrap;
}

.organization-of-users-head .icon {
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  margin-left: 5px;
}

.organization-of-users-new {
  display: flex;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
}

.organization-of-users-new:hover .icon {
  stroke: var(--sec-color);
}

.organization-of-users-new:hover h3 {
  color: var(--sec-color);
}

.organization-of-users-new:hover h2 {
  color: var(--sec-color);
}

.new-managed-user {
  display: flex;
  padding-left: 15px;
  align-items: center;
  border-bottom: 1px solid var(--bor-color);
  height: 40px;
  width: calc(100% - 15px);
}

.new-managed-user h2 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 10px;
}

.new-managed-user h3 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 10px;
}

@media only screen and (max-width: 1000px) {
  .new-managed-user-option h3 {
    display: none !important;
  }

  .new-managed-user-option-off h3 {
    display: none !important;
  }

  .new-managed-user h3 {
    min-width: 100px !important;
    max-width: 100px !important;
  }

  .new-managed-user h2 {
    min-width: 100px !important;
    max-width: 100px !important;
  }
}

@media only screen and (max-width: 650px) {
  .new-managed-user h3 {
    display: none !important;
  }
}

.new-managed-user:last-of-type {
  border-bottom: none;
}

.new-managed-user-option {
  display: flex;
  align-items: center;
  border-left: 1px;
  padding-right: 10px;
  border-left: 1px solid var(--bor-color);
  padding-left: 10px;
  height: 100%;
  cursor: pointer;
}

.new-managed-user .new-managed-user-option:last-of-type {
  padding-right: 0px;
}

.new-managed-user-option:hover {
  background: var(--sec-bg-color);
}

.new-managed-user-option:first-of-type {
  margin-left: auto;
}

.new-managed-user .new-managed-user-option:last-of-type {
  padding-right: 10px;
}

.new-managed-user-option .icon {
  stroke: var(--pri-color-light);
  width: 16px;
  height: 16px;
  margin-left: 5px;
}

.admin-option:hover {
  background: var(--bg-color);
}

.new-managed-user-option-off {
  display: flex;
  align-items: center;
  border-left: 1px;
  padding-right: 10px;
  border-left: 1px solid var(--bor-color);
  padding-left: 10px;
  height: 100%;
}

.new-managed-user-option-off h3 {
  color: var(--bor-color);
}

.new-managed-user-option-off .icon {
  stroke: var(--bor-color);
  width: 16px;
  height: 16px;
  margin-left: 5px;
}

.new-managed-user .new-managed-user-option-off:last-of-type {
  padding-right: 10px;
}
