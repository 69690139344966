.asset-type-filter {
  width: 390px;
  height: 245px;
  position: relative;
  border: 1px solid var(--bor-color);
  background: var(--bg-color);
  border-radius: 10px;
  padding: 20px;
}

.asset-type-filter .new-analysis-close {
  top: 20px;
  right: 20px;
  position: absolute;
}

.asset-type-filter .new-analysis-base-row:nth-of-type(2) {
  margin-top: 20px;
  flex-wrap: wrap;
  row-gap: 10px;
}
