.sidebar h5 {
  margin-left: 10px;
  transition: 0.2s;
  text-overflow: clip;
  white-space: nowrap;
  width: 0;
  opacity: 0;
}

.sidebar-back {
  min-width: 80px;
  max-width: 80px;
}

.sidebar {
  min-width: 80px;
  max-width: 80px;
  display: flex;
  flex-direction: column;
  transition: 0.2s;
  height: 100vh;
  z-index: 100;
  overflow: hidden;
  position: fixed;
}

.sidebar hr {
  width: 100%;
}

.sidebar-hover {
  min-width: 185px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.75) !important;
}

.sidebar-hover h5 {
  opacity: 1 !important;
  width: fit-content;
}

.sidebar-hover h3 {
  opacity: 1 !important;
  width: fit-content;
}

.sidebar-hover button {
  opacity: 1;
  width: 160px !important;
}

.sidebar h3 {
  width: 0;
  opacity: 0;
  font-size: 0.875rem;
  font-weight: 600;
  text-overflow: clip;
  white-space: nowrap;
}

.sidebar button {
  transition: 0.2s;
  margin-left: 10px !important;
  margin-top: 5px;
  background: var(--sec-color);
  width: 60px;
  height: 60px;
  display: flex;

  align-items: center;
  padding: 0;
  border-radius: 30px;
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%),
    0s 1px 3px 1px rgb(60 64 67 / 15%);
}

.sidebar button:hover {
  box-shadow: 0px 16px 10px 0px rgb(0 0 0 / 14%),
    0px 11px 18px 0px rgb(0 0 0 / 12%), 0px 13px 5px -1px rgb(0 0 0 / 20%) !important;
}

.sidebar button h3 {
  color: white;
}

.sidebar button .icon {
  margin-left: 13px;
  min-width: 35px !important;
  min-height: 35px !important;
  stroke: white;
}

.side-link {
  margin: 0 !important;
  width: 100%;
  display: flex;
}

.link-icon {
  margin: 0 !important;
  margin-left: 30px !important;
  min-width: 20px !important;
  min-height: 20px !important;
}

.side-link:nth-of-type(1) {
  margin-top: 10px !important;
}

.num-complete {
  position: absolute;
  border-radius: 100%;
  background: var(--sec-color);
  color: white;
  width: 16px;
  height: 16px;
  font-size: 11px;
  top: -16px;
  left: 8px;
  z-index: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}
