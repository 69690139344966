.binary-overview {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 10px;
}

.overview-binaries-green,
.overview-binaries-yellow,
.overview-binaries-red {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 80px;
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%),
    0s 1px 3px 1px rgb(60 64 67 / 15%);
  border-radius: 5px;
  position: relative;
}

.overview-binaries-green h2,
.overview-binaries-yellow h2,
.overview-binaries-red h2 {
  font-size: 32px !important;
}

.overview-binaries-green h3,
.overview-binaries-yellow h3,
.overview-binaries-red h3 {
  position: absolute;
  bottom: -25px;
  white-space: nowrap;
}

.overview-binaries-green {
  background: var(--green-color);
}

.overview-binaries-yellow {
  background: var(--yellow-color);
}

.overview-binaries-red {
  background: var(--contrast-color);
}

.binary-overview-wrapper {
  border: 2px solid var(--sec-bg-color);
  padding: 40px;
  padding-top: 35px;
  padding-bottom: 60px;
  margin-bottom: 30px;
  border-radius: 10px;
}
