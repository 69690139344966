.delta-report-form {
  overflow-y: scroll;
  height: calc(100vh - 228px);
  position: relative;
  padding: 40px;
}

.delta-report-form hr {
  width: 100%;
  margin-top: 60px;
  margin-bottom: 60px;
}

.delta-report-form h2 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}

.delta-report-form p {
  color: var(--pri-color-light);
  line-height: 1.4;
  text-align: left;
}

.delta-report-form .small-input {
  background: var(--sec-bg-color);
  max-width: 400px;
  border-radius: 15px;
  padding: 20px;
  margin-top: 20px;
  margin-right: 40px;
  font-size: 15px;
  font-weight: 400;
}

.delta-report-form textarea {
  background: var(--sec-bg-color);
  width: 100%;
  max-width: 600px;
  height: 140px;
  border-radius: 15px;
  padding: 20px;
  margin-top: 20px;
  margin-right: 40px;
  font-family: "Roboto";
  font-size: 15px;
  font-weight: 400;
}

.delta-report-form button {
  color: white;
  align-self: flex-end;
  background: var(--sec-color);
}

.delta-report-form-items {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
}

.delta-report-form .binary-summary-info-helper {
  margin-bottom: 100px;
  margin-top: 30px;
}

/* --- */

.delta-report-display {
  width: 100%;
  height: calc(100% - 80px);
}

.delta-report-display embed {
  margin-left: auto;
  margin-right: auto;
}

.delta-report-display-failure {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 20px;
}

.delta-report-display-failure .icon {
  stroke: var(--sec-bg-color);
  width: 100px;
  height: 100px;
}

.delta-report-display-failure h3 {
  color: var(--sec-bg-color);
  font-size: 20px;
}

.delta-report-display-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100% - 50px);
  gap: 20px;
}
