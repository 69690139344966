.cwe-navigator {
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 2px solid var(--sec-bg-color);
  border-top: 1px solid var(--bor-color);
  padding-left: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  position: sticky;
  background: var(--bg-color);
  width: 100%;
  top: 0px;
  transform: translate(-20px, -30px);
  z-index: 100;
}

.cwe-navigator h2 {
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  color: var(--pri-color-light);
}

.cwe-navigator h2:hover {
  text-decoration: underline;
  color: var(--pri-color);
}

.cwe-navigator .current-cwe {
  color: var(--pri-color);
  font-weight: 500;
}

.cwe-navigator .navigate {
  margin-right: 10px;
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  stroke: var(--pri-color-light);
}

.cwe-navigator .arrow-right {
  width: 16px;
  height: 16px;
  stroke: var(--pri-color-light);
}

.unified-cwes .mapping {
  flex-direction: column;
}

.cwe-children {
  border: 2px solid var(--sec-bg-color);
  border-radius: 10px;
  padding: 30px;
  overflow: hidden;
  margin-bottom: 30px;
}
.cwe-children h2 {
  margin-top: 10px;
  margin-bottom: 30px;
}

.cwe-children .bap-table {
  width: calc(100% + 20px);
  transform: translateX(-10px);
  margin-bottom: -30px;
}

.cwe-children table {
  border: none !important;
}

.cwe-searcher {
  position: relative;
  border-radius: 29px;
  padding: 15px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: var(--sec-bg-color);
  width: 200px;
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.cwe-searcher:hover {
  outline: 2px solid var(--sec-color);
}

.matched-cwes {
  position: absolute;
  background: var(--sec-bg-color);
  bottom: 0;
  left: -2px;
  width: 100%;
  transform: translateY(100%);
  border-bottom-right-radius: 29px;
  border-bottom-left-radius: 29px;
  border-left: 2px solid var(--sec-color);
  border-right: 2px solid var(--sec-color);
  border-bottom: 2px solid var(--sec-color);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important;
  overflow: hidden;
}

.matched-cwe {
  padding: 20px;
  white-space: pre;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow: hidden;
  border-top: 2px solid var(--bg-color);
  cursor: pointer;
  font-size: 14px;
}

.matched-cwe:hover {
  background: var(--bg-color);
}

.cwe-swap-tab-button {
  background: var(--sec-color);
  width: fit-content;
  margin-bottom: 30px;
  margin-top: -10px;
  margin-left: auto;
  color: var(--pri-color);
  background-color: var(--sec-bg-color);
  border-radius: 40px;
  padding: 20px;
  transition: 0.2s;
  font-size: 13px;
}

.cwe-swap-tab-button:hover {
  transform: scale(1.01);
  outline: 2px solid var(--sec-color);
}
