.generated-report-popup {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  z-index: 10;
  top: 0;
  left: 0;
}

.generated-report-popup div {
  height: 50%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  outline-offset: -2px;
  transition: 0.2s;
  font-weight: 600;
}

.generated-report-popup div:nth-of-type(1) {
  border-bottom: 1px solid var(--bor-color);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.generated-report-popup div:nth-of-type(2) {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.generated-report-popup div:hover {
  outline: 2px solid var(--sec-color);
  color: var(--sec-color);
}
