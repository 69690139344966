.copyright {
  margin-top: auto;
  width: calc(100% - 80px);
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  align-self: center;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  color: var(--pri-color-light);
  margin-right: 15px;
}

.copyright:hover,
.copyright-row a:hover {
  text-decoration: underline;
}

.copyright-row {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  max-width: 800px;
  margin-bottom: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 14px !important;
}
.copyright-row a {
  text-decoration: none;
  color: var(--pri-color-light);
  font-weight: 400;
  font-size: 14px;
}
