.facility-dropdown {
  box-shadow: 0px 16px 10px 0px rgb(0 0 0 / 14%),
    0px 11px 18px 0px rgb(0 0 0 / 12%), 0px 13px 5px -1px rgb(0 0 0 / 20%) !important;
  border: 1px solid var(--bor-color);
  border-radius: 10px;
  position: absolute;
  width: 400px;
  max-height: 500px;
  padding-bottom: 10px;
  top: 36px;
  left: -1px;
  z-index: 30;
  background: var(--sec-bg-color);
  border-top-left-radius: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.facility-dropdown .row {
  padding: 20px;
  width: calc(100% - 35px);
}

.new-facility-button-dropdown {
  background: var(--sec-color);
  color: white;
  margin-left: auto;
}

.facility-searhbar {
    width: 260px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid var(--bor-color);
  border-radius: 10px;
  padding: 5px;
}

.facility-searhbar .icon {
  stroke: var(--pri-color-light);
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.facility-row {
  display: flex;
  width: calc(100% - 40px);
  align-items: center;
  padding: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
  cursor: pointer;
}

.facility-row:hover {
    background: var(--bg-color);
}

.facility-row h2 {
    font-size: 14px;
    max-width: fit-content;
    min-width: fit-content;
}

.facility-row h3 {
    margin-left: 10px;
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.facility-row-map {
  min-width: 50px;
  max-width: 50px;
  height: 40px;
  overflow: hidden;
  margin-right: 10px;
  position: relative;
  background: var(--sec-bg-color);
  border: 1px solid var(--bor-color);
  margin-right: 10px;
  border-radius: 5px;
}
