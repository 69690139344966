.release-notes-div li {
  font-size: 15px;
  color: var(--pri-color-light);
}

.release-notes-div .row {
  margin-top: 0 !important;
  margin-left: 10px;
}

.release-notes-div ul {
  height: 229px;
  margin-left: 10px;
  margin-bottom: -10px;
  width: calc(100% - 20px);
}

.rele {
    color: var(--pri-color);
    font-weight: 500 !important;
}

.release-notes-div .version-number {
  color: var(--pri-color);
  font-weight: 600 !important;
  margin-bottom: 20px  !important;
  margin-left: 10px !important;
}