.new-analysis-input {
    background: var(--bg-color);
    font-family: 'Mulish', sans-serif;
    font-size: 15px;
}

.facility-modal-base-info {
    height: 430px !important;
}

.facility-modal-map-holder {
    height: 440px !important;
}
