/* --- */

.asset-selector {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 10px;
  border-radius: 15px;
  border: 2px solid var(--sec-bg-color);
  width: 300px;
  margin-top: 20px;
}

.asset-selector button {
  background: var(--sec-bg-color) !important;
}

.delta-incomplete-warning {
  color: var(--contrast-color) !important;
  margin-top: 20px;
  display: flex;
  gap: 20px;
}


.delta-incomplete-warning .icon {
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
}


/* --- */

.asset-selector-popup {
  background: var(--bg-color);
  border: 2px solid var(--sec-bg-color);
  width: 100%;
  left: -2px;
  top: -2px;
  box-shadow: var(--box-shadow-s);
  z-index: 100;
  width: 300px;
  margin-top: 20px;
}

.asset-selector-popup .searchbar {
  margin: 0;
  border-radius: 0;
}

.asset-selector-popup .searchbar .icon {
  stroke: var(--pri-color-light);
  margin-right: 10px;
}

.asset-selector-popup .internal-searchbar {
  border-radius: 0;
}

.asset-selector-popup .select-deep {
  width: 100%;
}

.delta-clickable-asset {
  padding: 20px;
  cursor: pointer;
  border-bottom: 1px solid var(--sec-bg-color);
  font-size: 15px;
}

.delta-clickable-asset:hover {
  background: var(--sec-bg-color);
}
