.headbar-alerts {
  position: relative;
  margin-left: 10px;
}

.headbar-alerts-button {
  background: var(--sec-bg-color);
  min-width: 30px !important;
  min-height: 30px !important;
  max-width: 30px !important;
  max-height: 30px !important;
  border-radius: 10px;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 0px !important;
}

.num-not-read {
  position: absolute;
  background: var(--sec-color);
  font-size: 10px;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  top: -5px;
  right: -5px;
  box-shadow: 0px 16px 10px 0px rgb(0 0 0 / 14%),
  0px 11px 18px 0px rgb(0 0 0 / 12%), 0px 13px 5px -1px rgb(0 0 0 / 20%);
}

.headbar-alerts-button:hover {
  outline: 2px solid var(--sec-color);
  transform: scale(1.05);
}

.headbar-alerts-button:hover .user,
.headbar-alerts-button:hover .icon {
  stroke: var(--sec-color);
}

.headbar-alerts-button .icon {
  stroke: var(--pri-color-light);
  width: 16px;
  height: 16px;
  margin: 0;
}

.alerts-popup {
  position: absolute;
  background: var(--bg-color);
  border-radius: 10px;
  border: 1px solid var(--bor-color);
  width: 400px;
  height: 360px;
  right: 0px;
  top: 35px;
  box-shadow: 0px 16px 10px 0px rgb(0 0 0 / 14%),
    0px 11px 18px 0px rgb(0 0 0 / 12%), 0px 13px 5px -1px rgb(0 0 0 / 20%);
  overflow: hidden;
  z-index: 500;
}

.alerts-popup h2 {
  font-size: 16px;
  margin-left: 20px;
  margin-top: 24px;
}

.alerts-popup .new-analysis-close {
  position: absolute;
  right: 20px;
  top: 20px;
}

.alerts-scroller {
  padding: 20px;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 198px;
  margin-top: 20px;
}

.alerts-footer {
  border-top: 1px solid var(--sec-bg-color);
  height: 60px;
  display: flex;
  align-items: center;
}

.alerts-footer button {
  background: var(--sec-color);
  color: white;
  margin-left: auto;
  margin-right: 20px;
  max-height: 40px;
  padding: 12px;

  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%),
    0s 1px 3px 1px rgb(60 64 67 / 15%);
}

.no-alerts {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transform: translate(-50%, -60%);
}

.no-alerts .icon {
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
  stroke: var(--sec-bg-color);
}
