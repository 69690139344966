.bot-arrow {
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  z-index: 10;
  border-top: 10px solid var(--bg-color);
}

.bot-arrow2 {
  position: absolute;
  bottom: -12px;
  left: -1px;
  width: 0;
  height: 0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-top: 11px solid var(--bor-color);
}

.bot-right-arrow {
  position: absolute;
  bottom: -10px;
  right: 0;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  z-index: 101;
  border-top: 10px solid var(--bg-color);
}

.bot-right-arrow2 {
  position: absolute;
  bottom: -12px;
  right: -1px;
  width: 0;
  height: 0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-top: 11px solid var(--bor-color);
}

.top-right-arrow {
  position: absolute;
  top: -10px;
  right: 0;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  z-index: 101;
  border-bottom: 10px solid var(--bg-color);
}

.top-right-arrow2 {
  position: absolute;
  top: -12px;
  right: -1px;
  width: 0;
  height: 0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-bottom: 11px solid var(--bor-color);
}

.top-left-arrow {
  position: absolute;
  top: -10px;
  left: 0;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  z-index: 101;
  border-bottom: 10px solid var(--bg-color);
}

.top-left-arrow2 {
  position: absolute;
  top: -12px;
  left: -1px;
  width: 0;
  height: 0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-bottom: 11px solid var(--bor-color);
}


.tri-tip {
  position: absolute;
  width: fit-content;
  transform: none;
  z-index: 10;
  box-shadow: 0px 16px 10px 0px rgb(0 0 0 / 14%),
    0px 11px 18px 0px rgb(0 0 0 / 12%), 0px 13px 5px -1px rgb(0 0 0 / 20%) !important;
}



.fac-see-more {
  cursor: pointer;
}

.fac-see-more:hover {
  text-decoration: underline;
}
