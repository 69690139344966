.start_user {
  height: 160px;
  display: flex;
  align-items: center;
  margin-top: 30px;
  padding: 20px;
}

.start_user_option {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 180px;
  border-radius: 20px;
  border: 1px solid transparent;
  cursor: pointer;
  transition: 0.2s;
  height: 180px;
}

.start_user_option:hover {
  background: var(--sec-bg-color);
  border: 1px solid var(--bor-color);
}

.start_user_option:hover h2 {
  color: var(--sec-color);
}

.start_user_option:hover .icon {
  stroke: var(--sec-color);
}

.start_user_option h2 {
  transition: 0.2s;
}

.start_user_option .icon {
  margin-bottom: 10px;
  height: 32px;
  width: 32px;
  transition: 0.2s;
}

.start_user hr {
  width: 2px;
  height: 50px;
}

.existing-user {
  display: flex;
  align-items: center;
  height: 30px;
  width: 410px;
  border-radius: 3px;
  cursor: pointer;
}

.existing-user:hover {
  background: var(--sec-bg-color);
}

.existing-user h2 {
  font-size: 14px;
  font-weight: 500;
}

.existing-user h2:nth-of-type(2) {
  color: var(--pri-color-light);
}
