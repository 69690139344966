.side-qr-code {
  position: absolute;
  width: fit-content;
  height: fit-content;
  right: 60px;
  top: 80px;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--bg-color);
  border: 1px solid var(--bor-color);
  box-shadow: 0px 16px 10px 0px rgb(0 0 0 / 14%),
    0px 11px 18px 0px rgb(0 0 0 / 12%), 0px 13px 5px -1px rgb(0 0 0 / 20%) !important;
  border-radius: 10px;
  animation: qr-slide 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

@keyframes qr-slide {
  0% {
    transform: translateX(300px);
  }
  100% {
    transform: none;
  }
}

.side-qr-code .icon {
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 10px;
  width: 16px;
  height: 16px;
}

.side-qr-code .icon:hover {
  stroke: var(--sec-color);
}
