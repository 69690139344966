.column-sort-arrows {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
}

.column-sort-arrows .icon {
  width: 16px;
  height: 16px;
  stroke: var(--bor-color);
  stroke-width: 3px;
}

.cve-table-header {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.column-sort-arrows .sort-arrow-sel {
  stroke: var(--pri-color-light);
}

.vulnerability-row-cve p {
  line-height: 1.4;
  white-space: pre-line;
}

.vulnerability-row-cve-hoverable:hover {
  background: var(--sec-bg-color);
  cursor: pointer;
}

.vulnerability-row-cve-grey {
  color: var(--bor-color);
}

.cve-severity-with-info {
  display: flex;
  gap: 10px;
}
.cve-severity-with-info .icon {
  width: 16px;
  height: 16px;
  stroke: var(--pri-color-light);
}
