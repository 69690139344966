.mapping {
  margin-bottom: 30px;
  padding: 30px;
  border: 2px solid var(--sec-bg-color);
  border-radius: 10px;
  display: flex;
  overflow: hidden;
}

.mapping-severity {
  border-radius: 100%;
  padding: 15px;
  background: var(--contrast-color);
  width: 20px;
  height: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important;
  font-weight: 800;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.mapping .row {
  margin-right: 60px;
}

.mapping .row .icon {
  stroke: var(--pri-color-light);
  padding: 5px;
  height: 14px;
  width: 14px;
  border-radius: 5px;
  background: var(--sec-bg-color);
  margin-left: 5px;
  cursor: pointer;
  transition: 0.2s;
  user-select: none;
}

.mapping .row .icon:hover {
  outline: 2px solid var(--sec-color);
  transform: scale(1.05);
}

.mapping .row h3 {
  margin-top: 6px;
  font-size: 12px;
}

.mapping h2 {
  margin-bottom: 30px;
  line-height: 1.4;
  width: calc(100% - 60px);
}

.mapping h3 {
  margin-bottom: 10px;
}

.mapping-field {
  background: var(--sec-bg-color);
  border-radius: 10px;
  padding: 20px;
  margin-top: 10px;
  margin-bottom: 30px;
  font-family: monospace;
  line-height: 1.4;
  min-width: calc(100% + 0px) !important;
}

.discovery-location {
  background: var(--sec-bg-color);
  border-radius: 10px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
  margin-bottom: 4px;
  font-family: monospace;
  min-width: calc(100% + 30px) !important;
  position: relative;
}

.discovery-location-clickable {
  transition: 0.2s;
  cursor: pointer !important;
}

.discovery-location-clickable pre,
.discovery-location-clickable span {
  cursor: pointer !important;
}

.discovery-location-clickable:hover {
  min-width: calc(100% + 24px) !important;
  transform: scale(1.01);
  outline: 2px solid var(--sec-color);
}

.discovery-location .deep-dive-line {
  margin-top: 91px;
  top: 0;
  position: absolute;
}

.page-count {
  margin-left: auto;
}

.mapping-field p {
  font-size: 12px;
  display: flex;
  flex-direction: row;
  text-align: left;
  line-height: 1.4;
}

.mapping-field p div {
  width: 180px;
}

.discovery-location p {
  font-size: 12px;
}

.extra-info-icon {
  stroke: var(--pri-color-light);
  padding: 10px;
  height: 16px;
  width: 16px;
  border-radius: 5px;
  background: var(--sec-bg-color);
  margin-right: 10px;
  cursor: pointer;
  transition: 0.2s;
  user-select: none;
}

.extra-info-icon-selected {
  stroke: var(--sec-color);
  outline: 2px solid var(--sec-color);
}

.extra-info-icon:hover {
  outline: 2px solid var(--sec-color);
  transform: scale(1.05);
}

.mappings-search {
  position: absolute;
  width: 100%;
  z-index: 20;
  padding: 10px;
  left: 0px;
  top: 27px;
  padding-left: 20px;
  border-top: 1px solid var(--bor-color);
  border-bottom: 1px solid var(--bor-color);
  background: var(--bg-color);
}
