.binary-table {
  background: var(--sec-bg-color);
  width: calc(100% - 20px);
  border-radius: 15px;
  position: relative;
}

.binary-table-title {
  padding: 20px;
  font-weight: 700;
  font-size: 14px;
}

.binary-table-row {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  transition: 0.2s;
}

.binary-table-row:hover {
  transform: scale(1.01);
  outline: 2px solid var(--sec-color);
}

.binary-table-row:nth-of-type(1) {
  cursor: auto;
}
.binary-table-row:nth-of-type(1):hover {
  transform: none;
  outline: none;
}

.binary-table-cell {
  width: calc(20% - 20px);
  max-width: calc(20% - 20px);
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 20px;
  border-right: 2px solid var(--bg-color);
  border-top: 1px solid var(--bg-color);
  min-height: 10px;
  max-height: 10px;
}

.binary-table-row .binary-table-cell:nth-of-type(1) {
  border-right: 4px solid var(--bg-color);
}

.binary-table-cell .light-g,
.binary-table-cell .light-y,
.binary-table-cell .light-r {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.binary-table-cell h2 {
  font-size: 15px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
.binary-table .binary-table-row:nth-of-type(1) .binary-table-cell h2 {
  font-weight: 600;
}

.binary-table .binary-table-row:nth-of-type(1) .binary-table-cell {
  border-top: 1px solid var(--bg-color);
  border-bottom: 2px solid var(--bg-color);
}

.binary-table-cell h3 {
  font-size: 15px;
}
