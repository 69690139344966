.crypto-container {
  border: 2px solid var(--sec-bg-color);
  border-radius: 10px;
  padding: 20px;
  padding-left: 40px;
  margin-bottom: 30px;
}

.crypto-container .row {
  display: flex;
  align-items: center;
  padding-right: 50px;
}

.crypto-container .row .page-count {
  margin-right: 5px;
}

.crypto-container .row .icon {
  stroke: var(--pri-color-light);
  padding: 5px;
  height: 14px;
  width: 14px;
  border-radius: 5px;
  background: var(--sec-bg-color);
  margin-left: 5px;
  cursor: pointer;
  transition: 0.2s;
  user-select: none;
}

.crypto-container .row .icon:hover {
  outline: 2px solid var(--sec-color);
  transform: scale(1.05);
}

.strong-enc-row {
  background: var(--sec-bg-color);
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  word-break: break-all;
  font-family: monospace;
  width: calc(100% - 70px);
}

.strong-enc-row hr {
  background: var(--bg-color) !important;
  margin: 0;
  margin-top: 20px;
  height: 4px;
  width: 100%;
}
