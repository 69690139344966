.score-cause {
  border: 2px solid var(--sec-bg-color);
  border-radius: 10px;
  padding: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.report-builder-component .score-cause {
  border: none;
  pointer-events: none;
  padding: 0px;
}

.report-builder-component .score-cause .left-row {
  padding-left: 0 !important;
}

.score-cause-clickable {
  cursor: pointer;
  transition: 0.2s;
}

.score-cause-clickable:hover {
  transform: scale(1.005);
  border: 2px solid var(--sec-color);
}

.score-cause .left-row {
  margin-bottom: 0;
}

.score-cause .spinner {
  width: 20px;
  height: 20px;
  stroke: var(--bor-color);
  margin-top: 10px;
  margin-left: 0;
}

.cause {
  width: 100%;
}

.cause h2 {
  margin-top: 10px;
  margin-bottom: 20px;
}

.cause h3 {
  line-height: 1.4;
}

.cause p {
  color: var(--pri-color-light);
  text-align: left;
  font-size: 16px;
  line-height: 1.4;
}

.cause-field {
  background: var(--sec-bg-color);
  border-radius: 10px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding: 20px;
  margin-bottom: 20px;
  font-family: monospace;
  width: calc(100% - 20px);
}
