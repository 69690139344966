.elk-logger-input {
  margin-top: 60px;
  margin-bottom: 200px;
}

.elk-logger-input .row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.elk-logger-input hr {
  justify-self: flex-start;
  margin: 0;
  margin-top: 30px;
  margin-bottom: 15px;
  max-width: 600px;
}

.elk-logger-input p {
  margin-top: 20px;
  color: var(--pri-color-light);
  max-width: 600px;
  text-align: left;
  margin-bottom: 15px;
}

.elk-logger-input input {
  border: 1px solid var(--bor-color);
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 8px;
  margin-top: 0;
}
.elk-logger-input div span {
  color: var(--pri-color-light) !important;
}

.elk-logger-input .ssl-enabled-txt {
  margin-left: -10px;
  margin-top: 2px;
  font-size: 14px;
}

.elk-logger-input input:nth-of-type(1) {
  width: 200px;
}

.elk-logger-input input:nth-of-type(2) {
  width: 100px;
}

.elk-logger-input button {
  background: var(--sec-color);
  color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: 0.2s;
}

.elk-logger-input button:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.disable-elk {
  background: var(--sec-bg-color) !important;
  color: var(--bor-color);
  box-shadow: none !important;
}

.elk-logger-input .protocol-select {
  width: 60px;
  border: 1px solid var(--bor-color);
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  font-weight: 700;
  position: relative;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.elk-logger-input .protocol-select .icon {
  width: 16px;
  height: 16px;
  margin-left: auto;
}

.protocol-select-drop {
  position: absolute;
  bottom: 0;
  transform: translateY(calc(100% - 5px));
  border: 1px solid var(--bor-color);
  border-top: 2px solid var(--sec-bg-color);
  width: 100%;
  left: -1px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: var(--box-shadow-l);
  background: var(--bg-color);
}

.protocol-select-drop div {
  padding: 10px;
}

.protocol-select-drop div:hover {
  background: var(--sec-bg-color);
}
