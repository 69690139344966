.deep-dive-line {
  position: absolute;
  left: 0px;
  width: calc(100% - 4px);
  height: 15px;
  border-left: 4px solid var(--contrast-color);
  background: rgba(235, 64, 52, 0.15);
  cursor: pointer;
}

.deep-dive-line:hover,
.deep-dive-line-highlighted {
  border-left: 6px solid var(--contrast-color);
  background: rgba(235, 64, 52, 0.2);
  width: calc(100% - 6px);
}

.deep-dive-line-invs {
  position: absolute;
  left: 0px;
  width: calc(100% - 4px);
  height: 15px;
  cursor: pointer;
}

.deep-dive-line-invs:hover,
.deep-dive-line-invs-highlighted {
  border-left: 6px solid var(--sec-color);
  background: rgba(66, 135, 245, 0.2);
}

.deep-dive-line .cate-tooltip {
  left: 280px;
}

.deep-dive-line-popup {
  top: 140px;
  left: 280px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 300px;
  padding: 20px;
}

.deep-dive-line-popup .row {
  width: 100%;
  justify-content: flex-start;
  margin-top: 10px;
  align-items: center;
  gap: 5px
}

.deep-dive-line-popup a {
  font-size: 15px;
  color: var(--pri-color-light);
}

.deep-dive-line-popup a:hover {
  text-decoration: underline;
}

.deep-dive-line-popup h3 {
  line-height: 1.4;
}

.deep-dive-line-popup h2 {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.deep-dive-line-popup .light-r {
  min-width: 9px;
  min-height: 9px;
}

/* Search feature. */

.deep-jumper {
  margin-left: auto !important;
  min-width: 200px;
  max-width: 200px;
  background: var(--bg-color);
  padding: 9px;
  border-radius: 5px;
  margin-right: 5px;
  transition: 0.2s;
}

.deep-jumper:focus {
  outline: 2px solid var(--sec-color);
}

.deep-jumper:hover {
  outline: 2px solid var(--sec-color);
}
