.head-prog {
  position: relative;
  padding: 5px;
  margin-right: 10px;
  height: fit-content;
  min-width: fit-content;
  max-width: fit-content;
}

.head-prog-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 100 !important;
  border-bottom: 2px solid transparent;
}

.progress-scroll {
  border-top: 2px solid var(--sec-bg-color);
  width: calc(100% - 20px);
  margin-top: 51px;
  overflow-y: scroll;
  max-height: calc(90vh - 200px);
  padding: 10px;
  overflow-x: hidden;
}

.head-prog:hover .head-prog-more {
  background: var(--bg-color);
  border: 1px solid var(--sec-color);
  box-shadow: 0px 16px 10px 0px rgb(0 0 0 / 14%),
    0px 11px 18px 0px rgb(0 0 0 / 12%), 0px 13px 5px -1px rgb(0 0 0 / 20%);
  max-height: 90vh;
}

.head-prog-more {
  overflow: hidden;
  position: absolute;
  width: calc(100% + 20px);
  top: -10px;
  left: -10px;
  border: 1px solid transparent;
  border-radius: 10px;
  height: fit-content;
  max-height: 40px;
}

.bin-prog-row {
  margin-left: 10px;
  padding-bottom: 10px;
  padding-right: 20px;
  display: flex;
  align-items: center;
}

.bin-prog-row h2 {
  font-size: 16px;
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 5px;
}

.bin-prog-row h3 {
  font-size: 14px;
  margin-left: 20px;
}

.bin-prog-row-circ {
  width: 50px;
  height: 50px;
  margin-left: auto;
}

.progress-scroll hr {
  width: calc(100% + 20px);
  transform: translateX(-10px);
}
