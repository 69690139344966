.audit-org-history {
  margin-top: 20px;
  border-left: 2px solid var(--bor-color);
  padding-left: 20px;
  margin-left: 5px;
}

.audit-entry {
    margin-bottom: 15px;
    position: relative;
    overflow: hidden;
    transform: translateX(-26px);
}

.audit-bubble {
    border-radius: 100%;
    width: 10px;
    height: 10px;
    background: var(--bor-color);
    position: absolute;
    left: 0px;
}

.audit-entry p {
    font-size: 14px;
    font-weight: 400;
    margin-top: 5px;
    text-align: left;
    margin-left: 26px;
}

.audit-org-history h3 {
    margin-left: 26px;
    font-size: 13px;
}

.view-all-audit {
    transform: translateX(-26px);
    cursor: pointer;
}

.view-all-audit:hover {
    text-decoration: underline;
}