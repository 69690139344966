.binary-summary-info-helper {
  display: flex;
  border: 2px solid var(--sec-bg-color);
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 30px;
}

.binary-summary-info-helper .icon {
  margin-right: 10px;
  stroke: var(--pri-color-light);
  min-width: 19px;
  min-height: 19px;
  max-width: 19px;
  max-height: 19px;
}

.binary-summary-info-helper h3 {
  line-height: 1.4;
}

.binary-summary-info-helper a {
  color: var(--sec-color);
}
