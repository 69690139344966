.report-builder-component {
  padding: 30px;
  padding-top: 0;
  border: 2px solid var(--sec-bg-color);
  border-radius: 10px;
  margin-bottom: 30px;
  background: var(--bg-color);
  overflow: hidden;
  position: relative;
}

.report-builder-component:hover {
  outline: 2px solid var(--sec-color);
}

.report-builder-component h3 {
  margin-bottom: 5px;
  margin-top: 30px;
}

.report-builder-component p {
  white-space: pre-line;
  text-align: left;
}

.report-builder-component .cause-field {
  margin-bottom: 0;
  margin-top: 10px;
  width: 100%;
}

.report-builder-component .cause-field p {
  overflow: hidden;
  white-space: pre;
  font-size: 12px;
  display: flex;
  flex-direction: row;
}

.report-builder-component .cause-field p div {
  width: 120px;
}

.report-builder-component .icon {
  stroke: var(--pri-color-light);
  padding: 5px;
  height: 14px;
  width: 14px;
  border-radius: 5px;
  background: var(--sec-bg-color);
  margin-left: 5px;
  cursor: pointer;
  transition: 0.2s;
  user-select: none;
}

.report-builder-component .icon:hover {
  outline: 2px solid var(--sec-color);
  transform: scale(1.05);
}

.report-builder-component .row h3 {
  margin-top: 6px;
}

.report-builder-component-close {
  position: absolute;
  top: 20px;
  right: 20px;
  stroke: var(--pri-color-light);
  cursor: pointer;
  transition: 0.2s;
}

.report-builder-component-close:hover {
  stroke: var(--sec-color);
}