.asset-summary-tags {
  display: flex;
  margin-bottom: 40px;
}

.asset-summary-tags h3 {
  font-weight: 500;
  font-size: 15px;
  min-width: 80px;
  max-width: 80px;
  margin-top: 4px;
}

.asset-summary-tags-inner {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.asset-summary-tags-inner .asset-tag {
  margin-left: 0;
  width: fit-content;
  padding: 5px;
  padding-left: 10px;
}

.asset-tag .icon {
  width: 14px;
  height: 14px;
  stroke: var(--pri-color-light);
  margin-left: 5px;
  cursor: pointer;
}

.asset-tag .icon:hover {
  stroke: var(--sec-color);
}

.asset-summary-tags-inner .asset-tag:before {
  border-top: 13px solid transparent;
  border-bottom: 12px solid transparent;
  border-right: 12px solid var(--bg-color);
  margin-top: -13px;
}

.asset-summary-tags-inner .asset-tag:after {
  border-top: 14px solid transparent;
  border-bottom: 13px solid transparent;
  border-right: 13px solid var(--bor-color);
  margin-top: -14px;
}

.asset-summary-tags-inner .tag-bull {
  margin-top: 4px;
}

.asset-description-component {
  border: 2px solid var(--sec-bg-color);
  padding: 30px;
  border-radius: 10px;
}
