.headbar {
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 0px;
  margin-left: auto;
  margin-right: auto;
  width: calc(100vw - 40px);
}

.headbar-hr {
  border: none;
  border-bottom: 2px solid var(--sec-bg-color);
  width: 100vw;
  margin-top: 6px;
  margin-bottom: 12px;
  background: none;
}

.project-logo {
  min-width: 80px;
  max-width: 80px;
  height: 40px;
  object-fit:cover;
  margin-right: auto;
  -webkit-backface-visibility: hidden;
}

.company-logo {
  width: 130px;
  margin-left: auto;
  margin-right: auto;
}

.headbar .icon-wrap {
  width: 34px;
  height: 34px;
  border: 1px solid var(--bor-color);
  border-radius: 5px;
  cursor: pointer;
  display: flex !important;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
}

.headbar .icon-wrap-sel {
  width: 34px;
  height: 34px;
  border: 1px solid var(--bor-color);
  border-radius: 5px;
  cursor: pointer;
  display: flex !important;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
  transform: scale(1.05);
  outline: 2px solid var(--sec-color);
}

.headbar .icon-wrap:hover {
  transform: scale(1.05);
  outline: 2px solid var(--sec-color);
}

.headbar .icon-wrap-sel:hover .icon {
  stroke: var(--sec-color);
}

.headbar .icon-wrap:hover .icon {
  stroke: var(--sec-color);
}

.headbar .icon {
  width: 16px;
  height: 16px;
  padding: 0;
  stroke: var(--pri-color-light);
}
