.big-drop-hovered {
  backdrop-filter: blur(5px);

  background: rgba(0, 0, 0, 0.2);
  border: 4px solid var(--sec-color) !important;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1000;
  width: calc(100vw - 8px);
  height: calc(100vh - 8px);
  animation: blur-it 0.2s linear;
}

@keyframes blur-it {
  0% {
    backdrop-filter: blur(1px);
    border: 4px solid transparent;
    background: rgba(0, 0, 0, 0);
  }
  100% {
    backdrop-filter: blur(5px);
    border: 4px solid var(--sec-color);
    background: rgba(0, 0, 0, 0.2);
  }
}

.big-drop {
  width: 100vw;
  height: 100vh;
}

.big-drop-hovered h1 {
  position: fixed;
  font-weight: 600;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  font-style: normal;
}
