.delta-reports-page {
  height: calc(100vh - 75px);
  width: 100%;
  min-width: calc(100vw - 291px);
  margin-left: auto;
  margin-right: auto;
  background: var(--bg-color);
}

/* --- */

.delta-report-form-header {
  padding: 30px;
  padding-bottom: 12px;
  padding-top: 12px;
  border-bottom: 2px solid var(--sec-bg-color);
  display: flex;
  align-items: center;
  gap: 20px;
}

.delta-report-form-header .icon:hover {
  outline: 2px solid var(--sec-color);
  transform: scale(1.01);
}

.delta-report-form-header .icon {
  stroke: var(--pri-color-light);
  background: var(--sec-bg-color);
  padding: 12px;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: 0.2s;
}

.delta-report-form-header h2 {
  font-size: 18px;
  font-weight: 500;
}
