.asset-cpes-tab {
  padding: 30px;
  overflow-x: hidden;
  overflow-y: scroll;
  height: calc(100vh - 160px);
}

.asset-cpes-tab .mapping {
  flex-direction: column;
}

.asset-cpes-tab .bap-table {
  width: calc(100% + 20px);
  transform: translateX(-10px);
  margin-bottom: -30px;
}

.asset-cpes-tab table {
  border: none !important;
}

.no-cpes-detected {
  margin-bottom: 0 !important;
}
