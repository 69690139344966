.fetchable-searchbar {
  position: relative;
}

 .internal-searchbar {
  border: none;
  height: 35px;
}

.internal-searchbar:hover {
  outline: 2px solid var(--sec-color);
}

.fetachable-popup {
  position: absolute;
  height: fit-content;
  width: calc(100% - 42px);
  background: var(--bg-color);
  bottom: -2px;
  transform: translateY(100%);
  border: 1px solid var(--bor-color);
  z-index: 30;
  padding: 20px;
  border-radius: 5px;
  box-shadow: var(--box-shadow-s);
}

.fetachable-popup h2 {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 20px;
}

.fetchable-options {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 10px;
}

.fetchable-options .new-analysis-option,
.fetchable-options .new-analysis-option-selected {
  display: flex;
  align-items: center;
}

.fetchable-options .icon {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.selected-fetchable {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  color: var(--pri-color-light);
  border: 1px solid var(--bor-color);
  border-radius: 5px;
  padding: 5px;
  margin-right: 5px;
  margin-left: 5px;
}

.selected-fetchable .icon {
  width: 15px;
  height: 15px;
  margin-left: 5px;
  stroke-width: 3px;
  cursor: pointer;
  transition: 0.2s;
}

.selected-fetchable .icon:hover {
  stroke: var(--sec-color);
}
