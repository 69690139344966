.report-builder-sidebar {
  min-width: 260px;
  max-width: 260px;
  background: var(--bg-color);
  margin-left: auto;
  border-left: 1px solid var(--bor-color);
  padding: 30px;
  z-index: 5;
}

.report-builder-sidebar h2 {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 25px;
  color: var(--pri-color-light);
}

.report-section-opt {
  background: var(--sec-bg-color);
  border-radius: 10px;
  padding: 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-top: 15px;
  cursor: pointer;
  transition: 0.2s;
}

.report-section-opt:hover {
  outline: 2px solid var(--sec-color);
  transform: scale(1.01);
}

.report-section-opt .icon {
  margin-left: auto;
  width: 16px;
  height: 16px;
}

.report-section-opt-back {
  margin-top: 0;
  width: 55px;
}
.report-section-opt-back .icon {
  margin-left: 0 !important;
}

.report-builder-sidebar-item {
  border: 2px solid var(--sec-bg-color);
  margin-top: 20px;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.2s;
  overflow: hidden;
}

.report-builder-sidebar-item:hover {
  outline: 2px solid var(--sec-color);
  transform: scale(1.01);
}

.report-builder-sidebar-item h3,
.report-builder-sidebar-item h2 {
  line-height: 1.4;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.report-builder-sidebar-item .cause-field {
  margin-top: 10px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.report-builder-sidebar-scroller {
  overflow-y: scroll;
  width: 100%;
  padding: 30px;
  padding-top: 0;
  transform: translateX(-30px);
  height: calc(100% - 57px);
  margin-top: 20px;
}

.report-builder-sidebar-item-unsel {
  pointer-events: none;
}

.report-builder-sidebar-item-unsel h3,
.report-builder-sidebar-item-unsel h2,
.report-builder-sidebar-item-unsel .cause-field {
  color: var(--bor-color);
}

.report-section-opt i {
  margin-left: 5px;
  color: var(--pri-color-light);
}

.report-builder-toggle-all {
  color: var(--pri-color-light);
  background: var(--sec-bg-color);
  transition: 0.2s;
  margin-left: auto;
}

.report-builder-toggle-all-on {
  color: var(--sec-color);
  outline: 2px solid var(--sec-color);
}

.report-builder-sidebar .toggle-all-row {
  display: flex;
  align-items: center;
}

.report-builder-sidebar .toggle-all-row h2 {
  margin-bottom: 0 !important;
}