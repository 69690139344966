.top-risk {
  border: 1px solid var(--bor-color);
  display: flex;
  flex-direction: row;
  padding: 20px;
  border-radius: 15px;
  margin-top: 20px;
  cursor: pointer;
  transition: 0.2s;
  overflow: hidden;
}

.top-risk:hover {
  background: var(--sec-bg-color);
}

.top-risk h2 {
  font-size: 15px;
  margin-bottom: 15px;
  line-height: 1.4;
}

.top-risk p {
  font-size: 15px;
  color: var(--pri-color-light);
  line-height: 1.4;
}

.top-risk-left {
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.top-risk-icon {
  width: 20px;
  height: 20px;
  stroke: var(--pri-color-light);
}

.top-risk-icon-red {
  border-radius: 100%;
  border: 2px solid var(--contrast-color);
  padding: 1px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 5px;
}

.top-risk-icon-yellow {
  border-radius: 100%;
  border: 2px solid var(--yellow-color);
  padding: 1px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.top-risk-icon-green {
  border-radius: 100%;
  border: 2px solid var(--green-color);
  padding: 1px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 5px;
}
