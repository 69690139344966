.report-concatenator {
  position: fixed;
  top: 60px;
  right: 70px;
  min-height: calc(100vh - 320px);
  max-height: calc(100vh - 320px);
  min-width: 250px;
  max-width: 250px;
  border: 1px solid var(--bg-color);
  margin-top: 100px;
  padding: 30px;
  border: 1px solid var(--bor-color);
  border-radius: 10px;
  background: var(--bg-color);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important;
  display: flex;
  flex-direction: column;
}

.concatenatable-report {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  background: var(--sec-bg-color);
  border-radius: 10px;
  padding: 10px;
}

.concatenatable-report:hover {
  outline: 2px solid var(--sec-color);
  outline-offset: -2px;
}

.generate-concatenatable-report-button {
  margin-top: auto;
  background: var(--sec-color);
  color: white;
}

.concatenatable-report .icon {
  stroke: var(--pri-color-light);
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

.concatenatable-report h2 {
  font-size: 14px;
  margin-bottom: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 160px;
}

.concatenatable-report h3 {
  font-size: 13px;
}

.remove-concatenatable-report {
  margin-left: auto;
  stroke: var(--pri-color-light);
  cursor: pointer;
  transition: 0.2s;
  width: 18px;
  height: 18px;
}

.remove-concatenatable-report:hover {
  stroke: var(--sec-color);
}

.concatenated-report-creation-progress {
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  margin-bottom: auto;
}

.concatenated-report-creation-progress h1 {
  font-style: normal;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 30px;
  text-align: center;
  line-height: 1.4;
}

.concatenated-report-creation-progress h3 {
  margin-top: 20px;
  line-height: 1.4;
  font-size: 15px;
  text-align: center;
}

.offscreen-concatenated-report {
  position: fixed;
  top: 0;
  left: -10000px;
  overflow-y: scroll;
}
