.login-page-wrap {
  min-height: 100vh;
  overflow-y: scroll;
}

.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100vw;
}



.login-center {
  box-shadow: var(--box-shadow-s);
  border-radius: 10px;
  padding: 40px;
  width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

.login-page h1 {
  margin-top: 40px;
  margin-bottom: 40px;
  font-weight: 600;
  font-style: normal;
}

.login-center h2 {
  margin-top: 5px;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 100 !important;
  color: var(--pri-color-light);
}

.login-center button {
  border-radius: 40px;
  box-shadow: var(--box-shadow-s);
  font-weight: 600;
  font-size: 18px;
  background: var(--sec-color);
  color: white;
}

.login-center button:nth-of-type(1) {
  margin-left: auto;
}

.fail-text {
  position: fixed;
  top: 50px;
  left: 50vw;
  transform: translateX(-50%);
}

.login-center p {
  margin-top: 30px;
  color: var(--pri-color-light);
  text-align: left;
}

.username-input {
  margin-bottom: 30px !important;
}

.login-center .row {
  margin-top: 40px;
  display: flex;
  align-items: center;
  width: 100%;
}

.login-center .row p {
  margin: 0;
  max-width: 200px;
}


.login-bot-row {
  display: flex;
  width: 400px;
  justify-content: space-evenly;
  margin-top: -20px;
  margin-bottom: 40px;
}

.login-bot-row a {
  color: var(--pri-color-light);
  font-size: 14px;
  font-weight: 400;
}

.login-bot-row a:hover {
  text-decoration: underline;
}

.login-decline-button {
  background: none !important;
  border: 1px solid var(--bor-color);
  color: var(--pri-color-light) !important;
  font-weight: 500 !important;
  margin-right: 20px;
}