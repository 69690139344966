.weak-pointer {
  border: 2px solid var(--sec-bg-color);
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 30px;
  overflow: hidden;
}

.weak-pointer-from-custom {
  border: none;
  width: 100%;
}

.weak-pointer .discovery-location {
  padding-left: 20px;
  transform: translateY(4px);
  border-radius: 0;
  height: 210px;
  transition: 0.5s;
  border: 1px solid var(--bor-color);
}

.weak-pointer .row {
  justify-content: flex-start;
  align-items: flex-start !important;
  min-width: 100%;
}

.weak-pointer h3 {
  margin-bottom: 5px;
  line-height: 1.4;
}

.weak-pointer h2 {
  margin-bottom: 30px;
}

.weak-pointer-path {
  margin-top: 20px;

  padding-left: 20px;
  margin-left: 5px;
  min-width: 30%;
  max-width: 30%;
}

.path-address {
  display: flex;
  border-left: 2px solid var(--bor-color);
  align-items: center;
  margin-left: 6px;
  position: relative;
  transform: translateX(-26px);
  cursor: pointer;
  min-width: calc(100% + 20px);
  height: 41.8px;
}

.path-address-no-hover:hover {
  background: transparent !important;
  cursor: default;
}

.path-address-sel {
  background: var(--sec-bg-color);
  border: 1px solid var(--bor-color);
  border-right: 0px;
  border-left: 2px solid var(--bor-color);
  z-index: 20;
}

.path-address:hover {
  background: var(--sec-bg-color);
}

.path-address-bubble {
  border-radius: 100%;
  width: 10px;
  height: 10px;
  background: var(--bor-color);
  position: absolute;
  left: -6px;
}

.path-address p {
  margin-top: 3px;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  margin-left: 26px;
}

.path-address h3 {
  margin-top: 5px;
  margin-left: 26px;
  font-size: 13px;
}

.path-address-show-all {
  cursor: pointer;
}

.path-address-show-all:hover {
  text-decoration: underline;
}

.weak-pointer .row:nth-of-type(2) {
  margin-top: 10px;
  align-items: center !important;
}

.weak-pointer-from-custom .row:nth-of-type(2) .icon:nth-of-type(2) {
  margin-right: 20px;
}

.weak-pointer .row:nth-of-type(2) h3 {
  margin-bottom: 0;
  font-size: 12px;
  margin-right: 10px;
}

.weak-pointer .row:nth-of-type(2) .icon {
  stroke: var(--pri-color-light);
  padding: 5px;
  height: 14px;
  width: 14px;
  border-radius: 5px;
  background: var(--sec-bg-color);
  margin-left: 5px;
  cursor: pointer;
  transition: 0.2s;
  user-select: none;
}

.weak-pointer .row:nth-of-type(2) .icon:hover {
  outline: 2px solid var(--sec-color);
  transform: scale(1.05);
}

.discovery-location .lds-dual-ring {
  position: absolute;
  top: 60%;
  left: 20%;
}

.path-address-bubble-final {
  background: var(--pri-color);
}

.path-address-text-final {
  font-weight: 800 !important;
  color: var(--pri-color);
}

.path-address-final {
  border-left: 2px solid var(--pri-color);
}

.weak-pointer-confidence {
  width: fit-content;
  padding: 15px;
  border-radius: 10px;
}

.clickable-associated-cwe {
  cursor: pointer;
}

.clickable-associated-cwe:hover {
  text-decoration: underline;
}