.binary-delta-reports {
  padding: 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  overflow-y: scroll;
  height: calc(100vh - 240px);
}

.binary-delta-report {
  background: var(--sec-bg-color);
  border-radius: 20px;
  padding: 30px;
  box-shadow: var(--box-shadow-s);
  cursor: pointer;
  transition: 0.2s;
  width: 200px;
  height: 240px;
  display: flex;
  flex-direction: column;
}

.binary-delta-report h2 {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
  margin-top: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.binary-delta-report h3 {
  margin-top: 10px;
}

.binary-delta-report:hover {
  outline: 2px solid var(--sec-color);
}

.delta-report-status {
  margin-top: 10px;
  border: 1px solid white;
  border-radius: 40px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  width: fit-content;
  font-weight: 500;
}

.all-delta-reports-header {
  display: flex;
  align-items: center;
  border-bottom: 2px solid var(--sec-bg-color);
  padding: 20px;
  gap: 15px;
}

.delta-report-searchbar {
  display: flex;
  align-items: center;
  gap: 10px;
  background: var(--sec-bg-color);
  padding: 15px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 40px;
  width: 400px;
}

.delta-report-searchbar .icon {
  stroke: var(--pri-color-light);
}

.all-delta-reports-header button {
  background: var(--sec-color);
  display: flex;
  align-items: center;
  color: white;
  gap: 5px;
}

.all-delta-reports-header button .icon {
  width: 18px;
  height: 18px;
  stroke: white;
  height: 100%;
}

/* --- */

.binary-delta-report-options {
  margin-left: auto;
  margin-bottom: 20px;
  position: relative;
}

.binary-delta-report-options .icon {
  transition: 0.2s;
  padding: 10px;
  border-radius: 100%;
}

.binary-delta-report-options .icon:hover {
  outline: 2px solid var(--sec-color);
}

.binary-delta-report-options-list {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(100%, -50%);
  box-shadow: var(--box-shadow-s);
}

.binary-delta-report-options-list div {
  padding: 20px;
  color: var(--contrast-color);
  background: var(--bg-color);
  border: 2px solid var(--sec-bg-color);
  width: 200px;
}

.binary-delta-report-options-list div:hover {
  background: var(--sec-bg-color);
}

.no-delta-reports {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 250px);
  gap: 20px;
}

.no-delta-reports .icon {
  min-width: 100px;
  min-height: 100px;
  max-width: 100px;
  max-height: 100px;
  stroke: var(--sec-bg-color);
}

.no-delta-reports h3 {
  line-height: 1.4;
  text-align: center;
}
