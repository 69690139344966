.an-org {
  padding-left: 10px !important;
  padding-right: 10px !important;
  overflow: hidden;
  width: 100%;
  overflow: hidden;
}

.an-org h3 {
  white-space: nowrap;
  font-weight: 500 !important;
}
