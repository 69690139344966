.report-builder-preview {
  width: 100%;
  position: relative;
  background: var(--bg-color);
}

.report-builder-preview h2 {
  font-size: 16px;
  font-weight: 600;
}

.report-builder-preview-internal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden;
  overflow-y: scroll;
  height: calc(100% - 30px);
  position: absolute;
  padding-top: 30px;
}

.fake-report-document {
  padding: 60px;
  padding-top: 50px;
  width: 695px;
  border: 2px solid var(--sec-bg-color);
  margin-bottom: 30px;
  background: var(--bg-color);
  box-shadow: 0px 16px 10px 0px rgb(0 0 0 / 14%),
    0px 11px 18px 0px rgb(0 0 0 / 12%), 0px 13px 5px -1px rgb(0 0 0 / 20%);
  transform-origin: top;
}

.fake-report-document h2,
.fake-report-document p,
.fake-report-document div,
.fake-report-document code,
.fake-report-document span {
  color: var(--pri-color) !important;
}


.fake-report-document-resized {
  transform: none !important;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  width: fit-content;
}

.fake-report-document-resized h2 {
  font-size: 10px !important;
}

.fake-report-document-resized h3 {
  font-size: 9px !important;
}

.fake-report-document-resized h4 {
  font-size: 8px !important;
}

.fake-report-document-resized div,
.fake-report-document-resized p,
.fake-report-document-resized textarea {
  font-size: 9px !important;
}

.fake-report-document-resized .cause-field p {
  display: flex !important;
}

.fake-report-document-resized .deep-dive-line {
  display: none;
}

.fake-report-document-resized .row-2,
.fake-report-document-resized .row {
  width: 510px !important;
}

.fake-report-document-resized #embedded-system-graph {
  transform: scale(0.85) translateX(-45px) !important;
}

.fake-report-document-resized .asset-description-component {
  width: 490px !important;
}

.fake-report-document-resized .report-builder-component .score-cause {
  width: 490px !important;
}

.fake-report-document-resized .score-cause {
  width: 510px !important;
}

.fake-report-document-resized .score-cause .left-row div {
  width: 510px !important;
}

.fake-report-document-resized .score-g {
  min-width: 20px !important;
  min-height: 20px !important;
  max-width: 20px !important;
  max-height: 20px !important;
}

.fake-report-document-resized .cause-field {
  font-size: 9px !important;
  max-width: 400px !important;
}

/* Scale the fake PDF preview based on window size. */
@media only screen and (max-width: 1400px) {
  .fake-report-document {
    transform: scale(0.95);
  }
}
@media only screen and (max-width: 1300px) {
  .fake-report-document {
    transform: scale(0.9);
  }
}
@media only screen and (max-width: 1250px) {
  .fake-report-document {
    transform: scale(0.85);
  }
}
@media only screen and (max-width: 1200px) {
  .fake-report-document {
    transform: scale(0.8);
  }
}
@media only screen and (max-width: 1150px) {
  .fake-report-document {
    transform: scale(0.75);
  }
}
@media only screen and (max-width: 1100px) {
  .fake-report-document {
    transform: scale(0.7);
  }
}

.report-builder-button {
  position: absolute;
  right: 5px;
}

.report-builder-button button {
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%),
    0s 1px 3px 1px rgb(60 64 67 / 15%);
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px !important;
  height: 30px !important;
  padding: 0;
  transition: 0.2s;
}

.report-builder-button button:hover {
  box-shadow: 0px 16px 10px 0px rgb(0 0 0 / 14%),
    0px 11px 18px 0px rgb(0 0 0 / 12%), 0px 13px 5px -1px rgb(0 0 0 / 20%);
  width: 40px !important;
}

.report-builder-button .pdf-download-button:hover {
  box-shadow: 0px 16px 10px 0px rgb(0 0 0 / 14%),
    0px 11px 18px 0px rgb(0 0 0 / 12%), 0px 13px 5px -1px rgb(0 0 0 / 20%);
  width: 40px !important;
}

.report-builder-button .icon {
  stroke: white;
  height: 16px;
  width: 16px;
}

.report-builder-button-close {
  top: 20px;
}

.report-builder-button-close button {
  background: var(--contrast-color);
}

.report-builder-button-docs {
  top: 60px;
}
.report-builder-button-docs button {
  background: var(--contrast-color);
}

.report-builder-button-download {
  top: 100px;
}
.report-builder-button-download .pdf-download-button {
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%),
    0s 1px 3px 1px rgb(60 64 67 / 15%);
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background: var(--sec-color);
  transition: 0.2s;
}

.fake-report-document .row {
  font-size: 12px;
  color: var(--pri-color-light);
  align-items: center;
}

.bottom-report-logo {
  width: 40px;
  margin-right: auto;
}
