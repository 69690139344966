.vert-pages-holder {
  position: relative;
  height: 100%;
}

.vert-pages {
  position: absolute;
  background: var(--bg-color);
  z-index: 50;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important;
  width: 160px;
  border: 1px solid var(--bor-color);
  right: 0;
}

.vert-page {
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
}

.vert-page:hover {
  background: var(--sec-bg-color);
}

.vert-pages-on-button {
  color: var(--pri-color);
  padding: 0;
  width: 40px;
  border-radius: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vert-pages-on-button:hover {
  background: var(--sec-bg-color);
}

.vert-pages-icon {
  width: 16px;
  height: 16px;
}
.vert-pages-icon:nth-of-type(1) {
  margin-right: -5px;
}
.vert-pages-icon:nth-of-type(2) {
  margin-left: -5px;
}

.toggle-header-item {
  background: var(--bg-color);
  cursor: pointer !important;
}
